import React from "react";
import styled from "styled-components";
import AliImage from "../images/ali_s.jpeg";
import ChristineImage from "../images/christine.jpeg";
import OzgeImage from "../images/ozge.jpeg";
import LinkedInIcon from "../images/linkedin-icon.png";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const TeamSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;
  overflow: hidden; // Ensures no overflow causing horizontal scrolling
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0 10px; // Adds padding to prevent content from touching the edges
  }
`;

const MemberContainer = styled.div`
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%; // Full width for each member to fit the mobile screen properly
  }
`;

const Heading = styled.h2`
  color: #111d57;
  font-size: 36px;
  margin-bottom: 30px;
  font-family: "Source Sans Pro";

  

  @media (max-width: 768px) {
    font-size: 28px; // Slightly smaller font size for mobile
  }
`;

const Description = styled.h3`
  color: #666;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 40px;
  line-height: 1.5;
  font-family: "Source Sans Pro";


  @media (max-width: 768px) {
    font-size: 18px; // Smaller font size for better readability on mobile
  }
`;

const Image = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 110px;
    height: 110px; 
  }
`;

const Name = styled.h3`
  color: #111d57;
  font-weight: bold;
  font-size: 22px;
  font-family: "Source Sans Pro";

  @media (max-width: 768px) {
    font-size: 18px; // Smaller font size for mobile
  }
`;

const Title = styled.h2`
  color: #999;
  font-size: 18px;
  font-family: "Source Sans Pro";
`;

const Bio = styled.p`
  color: #666;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 16px; // Reduced font size to prevent overflow
  }
`;

const LinkedInLogo = styled.img`
  height: 28px;
`;

const AboutUsTeam: React.FC = () => {
  return (
    <TeamSection>
      <GlobalStyles /> {/* Include global styles */}
      <div>
        <Heading>Our Team</Heading>
        <Description>
          We believe clean technologies can save consumers hundreds of dollars
          on their energy spending and combat climate change.
        </Description>
      </div>
      <ContentContainer>
        <MemberContainer>
          <Image src={AliImage} alt="Ali Sarilgan" />
          <Name>Ali Sarilgan</Name>
          <Title>CEO & Co-Founder</Title>
          <Bio>
            Previously led corporate strategy team at a consumer company and
            advised clean technology providers. Studied Management Science &
            Engineering at Stanford.
          </Bio>
          <a href="https://www.linkedin.com/in/alisarilgan" target="_blank" rel="noopener noreferrer">
            <LinkedInLogo src={LinkedInIcon} alt="LinkedIn" />
          </a>
        </MemberContainer>
        <MemberContainer>
          <Image src={OzgeImage} alt="Ozge Islegen-Wojdyla" />
          <Name>Ozge Islegen-Wojdlya</Name>
          <Title>CTO & Co-Founder</Title>
          <Bio>
            Former Assistant Professor at Northwestern and Stanford PhD focused on residential energy research.
            Previously led statistical interventions and testing as Senior Data Scientist at Instacart.
          </Bio>
          <a href="https://www.linkedin.com/in/ozge-islegen-wojdyla/" target="_blank" rel="noopener noreferrer">
            <LinkedInLogo src={LinkedInIcon} alt="LinkedIn" />
          </a>
        </MemberContainer>
        <MemberContainer>
          <Image src={ChristineImage} alt="Christine Manegan" />
          <Name>Christine Manegan</Name>
          <Title>Founding Engineer</Title>
          <Bio>
            Former data and infrastructure engineer at Snapchat,
            and hardware-to-software integration engineer at Apple.
            Studied Computer Science at Stanford.
          </Bio>
          <a href="https://www.linkedin.com/in/christine-astoria-manegan-6a4812159/" target="_blank" rel="noopener noreferrer">
            <LinkedInLogo src={LinkedInIcon} alt="LinkedIn" />
          </a>
        </MemberContainer>
      </ContentContainer>
    </TeamSection>
  );
};

export default AboutUsTeam;

