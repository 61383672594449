import { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import mixpanel from "mixpanel-browser";
import Navbar from "../components/Navbar";
import { supabase } from "../supabaseClient";
import fixedIcon from '../images/fixed_icon.png';
import saveIcon from '../images/save_homepage_icon.png';
import greenerIcon from '../images/greener_icon.png';
import {constructFullApiURL} from "../constructFullApiUrl";
import { redirectToTesting } from "../redirectToTesting";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const fadeInOutSequence = keyframes`
  0%, 100% { opacity: 0; }       // Start and end invisible
  10% { opacity: 0; }            // Stay invisible for the first 10%
  15%, 25% { opacity: 1; }       // Appear and stay visible for a short time
  30%, 100% { opacity: 0; }      // Fade out and stay out for the reset duration
`;


const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 70%;
  margin-top: 25px;
  @media (max-width: 768px) {
    width: 93%;
  }
`;

const IconBlock = styled.div<{ delay: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  margin-top: 20px;
  animation: ${fadeInOutSequence} 9s ease-in-out ${props => props.delay} infinite;
`;

const Icon = styled.img`
  width: 85px;
  height: 85px;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const IconTitle = styled.p`
  font-size: 24px;
  color: #253D80;
  font-weight: bold;
  font-family: 'Source Sans Pro';
  text-align: center;
  margin-top: 10px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const IconSubtitle = styled.p`
  font-size: 20px;
  color: #333333;
  font-weight: normal;
  font-family: 'Source Sans Pro';
  text-align: center;
  margin-top: -20px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-top: -10px;
    max-width: 140px;
  }
`;

const LoadingIndicator = styled.div`
  margin: 20px 0;
  height: 4px;
  width: 100%;
  background-color: #ccc;
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    position: absolute;
    content: "";
    left: 0;
    width: 0; // Start with a width of 0
    height: 100%;
    background-color: #284389;
    animation: loading 60s ease-out forwards;
  }

  @keyframes loading {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`;

const Button = styled.button`
  padding: 18px 40px;
  background-color: #284389;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #111d57;
  }
  @media (max-width: 768px) {
    width: 90%;
    font-size: 14px;
    padding: 18px 40px;
  }
 
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e6f7ff;
  width: 100%;
  height: 150vh;  // Full screen height
`;

const NewQuoteSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 50px 80px 50px;
  text-align: center;
  width: 80vw;
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: bold;
  color: #111d57;
  width: 65%;
  line-height: 1.5;
  top-padding: 15px;


  @media (max-width: 768px) {
    font-size: 24px; // Smaller font size for mobile devices
    width: 100%;
    top-padding: 0px;
  }

`;

const Subtitle = styled.p`
  color: #666;
  font-size: 20px;
  width: 65%;
  line-height: 1.5;
  top-padding: 15px;


  @media (max-width: 768px) {
    font-size: 18px; 
    width: 100%;
    top-padding: 0px;
  }
`;

const EmailInput = styled.input`
  margin: 60px 0px 40px 0px;
  padding: 10px;
  border: 2px solid #111d57;
  border-radius: 5px;
  font-size: 22px;
  background-color: #e6f7ff;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 18px;
`;


const PgeOAuthRedirect = () => {

  const [stage, setStage] = useState("Fetching token");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [quoteMessage, setQuoteMessage] = useState("Fetching your quote...");
  const [showRetryButton, setShowRetryButton] = useState(false);
  const navigate = useNavigate();

  // LOCAL TEST SECTION END

  // const handleAcceptClick = async () => {
  //   if (!validateEmail(email)) {
  //     return;
  //   }


  //   const { error } = await supabase
  //     .from("user-profiles")
  //     .update({ accepted_quote: true, email: email })
  //     .eq("monthly_quote", monthlyQuote);
  //   if (error) {
  //     console.log("Error updating status of user: ", error);
  //   }
  //   setStage("Accepted quote");
  // };

     interface TrackingFlags {
      unexpTokenFetch: boolean;
      generateQuote: boolean;
      showQuote: boolean;
      docusign: boolean;
      quoteError: boolean;
      insufficientDataForQuote: boolean;
      unexpSubmitEmail: boolean;
      // Index signature
      [key: string]: boolean;
    }
  
    const [trackingFlags, setTrackingFlags] = useState<TrackingFlags>({
      unexpTokenFetch: false,
      generateQuote: false,
      showQuote: false,
      docusign: false,
      quoteError: false,
      insufficientDataForQuote: false,
      unexpSubmitEmail: false
    });
  
    const trackEventOnce = (eventName: string) => {
      // TODO: Delete log statements below
      console.log("Tracking: ", eventName)
      console.log("Tracking Flags: ", trackingFlags)
      if (!trackingFlags[eventName]) {
        mixpanel.track(eventName);
        setTrackingFlags({ ...trackingFlags, [eventName]: true });
      }
    };
  
  const handleEmailClick = async (reason: string) => {
    if (!validateEmail(email)) {
      return;
    }
    const { error } = await supabase
      .from('failed-quote-generation')
      .insert([{ email: email, reason: reason }])
      .select()

    if (error) {
      console.log("Error updating status of user: ", error);
    }
    else {
      setStage("Submitted Email");
    }
  };


  const checkQuoteStatus = useCallback((taskId: string) => {
    const navigateToShowQuote = (monthlyQuote: string, highestBill: string, averageBill:string, email: string) => {
      const params = new URLSearchParams();
      params.append("monthlyQuote", monthlyQuote.toString());
      params.append("highestBill", highestBill);
      params.append("averageBill", averageBill.toString());
      params.append("email", email);
      // Construct the full URL
      const showQuoteUrl = `/show-quote?${params.toString()}`;
      // Use the navigate function to navigate to the constructed URL
      navigate(showQuoteUrl);
    }

    console.log("Start of call");
    const apiURL = constructFullApiURL(`/task-status/${taskId}`);
    fetch(apiURL)
      .then((response) => response.json())
      .then((data) => {
        console.log("calling", data);

        if (data.status === "complete") {
          if (data.data.result.message === "Insufficient Data") {
            setStage("Insufficient Data");
          }
          else {
            const queryParams = new URLSearchParams(window.location.search);
            const state = queryParams.get("state");
            let emailVal = null;
            let skipQuote = null;
            if (state) {
              skipQuote = state.charAt(1);
              emailVal = state.slice(2);
            }
            else {
              setStage("Error Generating Quote");
              return
            }
            if (skipQuote === "1") {
              navigate(`/request-emailed-quote?email=${encodeURIComponent(emailVal)}`);
            }
            else {
              console.log("Task complete, data:", data.data);
              const monthlyQuote = data.data.result.monthlyQuote;
              const highestBill = data.data.result.highestBill;
              const averageBill = data.data.result.averageBill;
              // Create a new URLSearchParams object and append the parameters
              navigateToShowQuote(monthlyQuote, highestBill, averageBill, emailVal);
            }
          }
        } else if (data.status === "error") {
          console.error("Error in task:", data.message);
          setStage("Error Generating Quote");
        } else {
          console.log("Task still processing...");
          setTimeout(() => checkQuoteStatus(taskId), 4000); // Check again in 4 seconds
        }
      });
  }, [navigate, setStage]);

  const exchangeAuthorizationCodeForAccessToken = useCallback(
    (authCode: string, email: string, skipQuote?: boolean) => {
      console.log("AUTH CODE: ", authCode);
      console.log("EMAIL: ", email);

      const apiURL = constructFullApiURL("/auth-code-exchange");
      fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: authCode,
          email: email,
          skipQuote: skipQuote,
        }),
      })
        .then((response: any) => {
          if (!response.ok) {
            throw new Error(
              "Failed to exchange authorization code for access token."
            );
          }
          return response.json();
        })
        .then((data) => {
          console.log("data:", data);
          if (!skipQuote) {
            setStage("Fetching quote");
          }
          checkQuoteStatus(data.taskId);
        })
        .catch((error) => {
          console.error("Error:", error);
          setStage("Error Generating Quote");
        });
    },
    [checkQuoteStatus]
  );

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.search;
    console.log("URL BEFORE REDIRECT", currentUrl);
    // Extract query parameters from the URL
    const urlParams = new URLSearchParams(currentUrl);
    redirectToTesting(urlParams, 'pge-oauth-redirect');
  }, []);

  useEffect(() => {

    const timers = [
      setTimeout(() => setQuoteMessage("Calculating your energy needs..."), 10000),
      setTimeout(() => setQuoteMessage("Preparing your guaranteed savings..."), 20000),
    ];

    const requiredScopes = new Set([
      "1",
      "3",
      "8",
      "13",
      "14",
      "18",
      "19",
      "31",
      "32",
      "35",
      "37",
      "38",
      "39",
      "4",
      "5",
      "15",
      "16",
      "46",
      "47",
    ]);
    const extractScopesFromURL = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const scopeString = urlParams.get("scope");
      if (scopeString) {
        const scopes = scopeString.split(";").reduce((acc, curr) => {
          const [key, value] = curr.split("=");
          if (key === "FB") {
            value.split("_").forEach((scope) => acc.add(scope));
          }
          return acc;
        }, new Set());
        return scopes;
      }
    };
    const checkRequiredScopes = (scopes: any) => {
      return [...requiredScopes].every((scope) => scopes.has(scope));
    };
    const scopes = extractScopesFromURL();
    if (!checkRequiredScopes(scopes)) {
      setShowRetryButton(true);
    } else {
      setShowRetryButton(false);
    }
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");
    let emailVal = null;
    let skipQuote = null;
    if (state) {
      skipQuote = state.charAt(1);
      emailVal = state.slice(2);
    }
    if (emailVal ) {
      setEmail(emailVal);
    }
    else {
      setStage("Error Generating Quote");
      return
    }

    console.log("skip quote in url", skipQuote);
    let skipQuoteBool = false;
    if (skipQuote) {
      if (skipQuote === "1") {
        skipQuoteBool = true;
      }
    }
    console.log("skip quote boolean", skipQuoteBool);

    if (code && emailVal) {
      // setAuthCode(code);
      console.log("code", code);
      exchangeAuthorizationCodeForAccessToken(code, emailVal, skipQuoteBool);
    }
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [exchangeAuthorizationCodeForAccessToken]);

  const handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Please enter a valid email address.");
      return false;
    }
  };

  // const handleNextClick = () => {
  //   setStage("Accepted quote");
  // };

  const renderStageComponent = () => {
    switch (stage) {
      case "Fetching token":
        trackEventOnce("unexpTokenFetch");
        return (
          <>
            <Title>Completing PG&E connection...</Title>
            <Subtitle>This will take a few moments</Subtitle>
          </>
        );
      case "Fetching quote":
        trackEventOnce("generateQuote");
        return (
          <>
            <Title>{quoteMessage}</Title>
            <Subtitle>This will take about 1 minute</Subtitle>
            <LoadingIndicator />
            <Subtitle>PG&E prices are 27%+ higher this year</Subtitle>
            <Title>Lock in guaranteed prices with EcoTrove</Title>
            <IconContainer>
              <IconBlock delay="1.5s">
                <Icon src={fixedIcon} alt="Fixed Price Icon" />
                <IconTitle>Predictable</IconTitle>
                <IconSubtitle>Fixed monthly pricing</IconSubtitle>
              </IconBlock>
              <IconBlock delay="3s">
                <Icon src={saveIcon} alt="Savings Icon" />
                <IconTitle>Cheaper</IconTitle>
                <IconSubtitle>Up to 10% savings</IconSubtitle>
              </IconBlock>
              <IconBlock delay="4.5s">
                <Icon src={greenerIcon} alt="Greener Energy Icon" />
                <IconTitle>Greener</IconTitle>
                <IconSubtitle>100% Clean Electricity</IconSubtitle>
              </IconBlock>
            </IconContainer>
          </>
        );
        case "Error Generating Quote":
          return (
            <>
              <Title>Our apologies, we ran into an error generating your quote.</Title>
              <Subtitle>
                Please enter your email to be notified when we are available or try again. 
              </Subtitle>
              <EmailInput
              type="text"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailInputChange}
            ></EmailInput>
            {emailError && <ErrorText>{emailError}</ErrorText>}
            <Button onClick={() => handleEmailClick("Error Generating Quote")}>
              Submit Email
            </Button>
          </>
        );
      case "Insufficient Data":
        trackEventOnce("insufficientDataForQuote");
        return (
          <>
            <Title>Your account is too new for us to forecast your energy demand. We will notify you as soon as you are eligible.</Title>
            <Subtitle>
              Please enter your email to be notified.
            </Subtitle>
            <EmailInput
              type="text"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailInputChange}
            ></EmailInput>
            {emailError && <ErrorText>{emailError}</ErrorText>}
            <Button onClick={() => handleEmailClick("Insufficient data")}>
              Submit Email
            </Button>
          </>
        );
      case "Submitted Email":
        trackEventOnce("unexpSubmitEmail");
        return (
          <>
            <Title>Thanks for submitting your email</Title>
            <Subtitle>
              We will be in touch within 24 hours.
            </Subtitle>
          </>
        );
      // case "Accepted quote":
      //   return (
      //     <>
      //       <Title>Welcome to EcoTrove!</Title>
      //       <Subtitle>
      //         Thanks for joining. Click below to review and sign our contract. 
      //         </Subtitle>
      //         <Button onClick={handleStartNowClick}>
      //         Start Now!
      //       </Button>
      //     </>
      //   );
    }
  };

  const handleReturnPGE = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const scopeString = urlParams.get("scope");
    let brValue = "";

    if (scopeString !== null) {
      // Split the scope string by semicolon and loop through each segment
      scopeString.split(";").forEach((item) => {
        const [key, value] = item.split("=");
        // Check if the current segment contains the BR key
        if (key === "BR") {
          brValue = value;
        }
      });
    }

    if (brValue) {
      const retryUrl = `https://sharemydata.pge.com/myAuthorization/auth/new/${brValue}`;
      window.location.href = retryUrl;
    } else {
      console.error("BR value not found in URL. Cannot retry authorization.");
    }
  };

  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
    <PixelTracking />
   </Helmet> 

    <main>
    <Container>
      <Navbar />
      <NewQuoteSection>
        {showRetryButton ? (
          <>
            <Title>Oops, we need a bit more information</Title>
            <Subtitle>
              In order to provide your quote, we will need access to all scopes
              from PG&E
            </Subtitle>
            <Subtitle>
              Click below to go back to PG&E and authorize all scopes
            </Subtitle>
            <Button onClick={handleReturnPGE}>Go back to PG&E</Button>
          </>
        ) : (
          <>{renderStageComponent()}</>
        )}
      </NewQuoteSection>
    </Container>
    </main>
  </>
  );
};

export default PgeOAuthRedirect;
