import React from 'react';
import { Helmet } from 'react-helmet';

const PixelTracking = () => {
  return (
    <div>
      {/* Google Ads Conversion Tracking Script */}
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16533430474"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16533430474');
          `}
        </script>
      </Helmet>

      {/* Reddit Pixel */}
      <Helmet>
        <script>
          {`
            !function(w,d){
              if(!w.rdt){
                var p=w.rdt=function(){
                  p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)
                };
                p.callQueue=[];
                var t=d.createElement("script");
                t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;
                var s=d.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(t,s)
              }
            }(window,document);
            rdt('init','a2_ehwues1yjch0', {
              "aaid":"<AAID-HERE>",
              "email":"<EMAIL-HERE>",
              "idfa":"<IDFA-HERE>"
            });
            rdt('track', 'PageVisit');
          `}
        </script>
      </Helmet>

      {/* Meta Pixel Code */}
      <Helmet>
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '498222132870805');
            fbq('track', 'PageView');
          `}
        </script>
      </Helmet>

      {/* TikTok Pixel Code Start */}
      <Helmet>
        <script>
          {`
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
              var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
              ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
            
              ttq.load('CPSPBFBC77U5FLG9OSH0');
              ttq.page();
            }(window, document, 'ttq');
          `}
        </script>
      </Helmet>

      {/* LinkedIn Ads Tracking Code */}
      <Helmet>
        <script type="text/javascript">
          {`
            _linkedin_partner_id = "7313729";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `}
        </script>
        <script type="text/javascript">
          {`
            (function(l) {
              if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
              window.lintrk.q=[]}
              var s = document.getElementsByTagName("script")[0];
              var b = document.createElement("script");
              b.type = "text/javascript";b.async = true;
              b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
              s.parentNode.insertBefore(b, s);
            })(window.lintrk);
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default PixelTracking;
