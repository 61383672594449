import styled, { keyframes } from "styled-components";
import Navbar from "../components/HomepageNavbar";
import ResidentialHeroSection from "../components/ResidentialHeroSection";
import BottomLandingSectionGetQuote from "../components/BottomLandingSectionGetQuote";
import Footer from "../components/Footer";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
import PixelTracking from "../components/PixelTracking";
import { Helmet } from 'react-helmet';
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import wattson_icon from "../images/wattson.png"; // New image import

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`;

const PlasmicComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// Keyframes for the icon animations
const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

// New styled component for the Wattson icon
const WattsonIconWrapper = styled.div`
  position: fixed;
  bottom: 2px; // Changed from 10px to 2px
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  width: 190px;
  height: 265px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: 
    ${slideUp} 0.5s ease-out 4s forwards, // Slide up animation after 3s delay
    ${bounce} 0.5s ease-in-out 4.5s 2; // Bounce twice after sliding up
  animation-fill-mode: forwards; // Ensure final state is preserved
  transition: transform 0.3s ease; // Smooth transition for hover effect

  &:hover {
    transform: scale(1.2); // Increase size on hover
  }

  @media (max-width: 768px) {
    width: 95px;
    height: 135px;
    bottom: 9px; 
    right: 9px;
    animation: 
    ${slideUp} 0.5s ease-out 5.5s forwards, // Slide up animation after 3s delay
    ${bounce} 0.5s ease-in-out 6s 2; // Bounce twice after sliding up
  }
`;

const WattsonIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease; // Smooth transition for hover effect

  &:hover {
    transform: scale(1.2); // Increase size on hover
  }
`;

const LandingPageResidential = () => {
  const handleWattsonIconClick = () => {
    window.open('mailto:info@ecotrove.com?subject=Question%20about%20my%20quote', '_blank');
  };

  return (
    <>
      <GlobalStyles /> {/* Include global styles */}
      <Helmet>
        <title>EcoTrove | Lower Energy Bills with Fixed Prices</title>
        <meta name="description" content="EcoTrove replaces your utility bills with a fixed-price clean energy subscription up to 10% cheaper. Discover how you can pay less for greener, predictable, power." />
        <meta name="keywords" content="fixed-price energy, clean energy subscription, lower energy bills, EcoTrove, green power" />
        <PixelTracking />
      </Helmet>

      <main>
        <PageContainer>
          <PlasmicRootProvider loader={PLASMIC}>
            <PlasmicComponent component="residential_topbar" />
          </PlasmicRootProvider>
          <Navbar />
          <ResidentialHeroSection />
          <PlasmicComponentsContainer>
            <PlasmicRootProvider loader={PLASMIC}>
              <PlasmicComponent component="ResidentialHomePageExplainer" />
            </PlasmicRootProvider>
          </PlasmicComponentsContainer>
          <BottomLandingSectionGetQuote />
          <Footer />
          <WattsonIconWrapper onClick={handleWattsonIconClick}>
            <WattsonIcon src={wattson_icon} alt="Wattson Icon" />
          </WattsonIconWrapper>
        </PageContainer>
      </main>
    </>
  );
};

export default LandingPageResidential;
