import { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import mixpanel from "mixpanel-browser";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
import { Helmet } from 'react-helmet';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

import background1 from '../images/background1.jpeg';
import background2 from '../images/background2.jpeg';
import background3 from '../images/background3.jpeg';
import background4 from '../images/background4.jpeg';
import background5 from '../images/background5.jpeg';
import background6 from '../images/background6.jpeg';

// Define the zoom-in keyframes for the background images
const zoomIn = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

// Define a container for the background image slideshow
const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; // Ensures it stays behind other content
  overflow: hidden;
`;

// Define a styled component for each background image with the zoom-in effect
const BackgroundImage = styled.div<{ image: string; isVisible: boolean }>`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.75s ease-in-out;

  ${(props) =>
    props.isVisible &&
    css`
      animation: ${zoomIn} 5.5s forwards;
      animation-fill-mode: forwards; // Ensures the image stays at scale 1.1
    `}
`;

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh; 
  padding: 25px 20px 0px 20px; 
  box-sizing: border-box;
  position: relative;
  overflow: hidden; // Ensure the background images are contained within the container
`;

const PGEWhitespace = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  background-color: rgba(255, 255, 255, 0.8); // Semi-transparent white background
  padding: -30px 50px 70px 50px;
  margin-top: -30px;
  box-sizing: border-box;
  border-radius: 25px;
  height: 68vh; 
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px 5px 10px 5px;
    margin-top: 0px;
    height: 90vh; 
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  font-family: 'Source Sans Pro';
  color: #284389;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 25px;
    width: 100%;
    text-align: center;
    margin-bottom: 2px;
    padding-top: 15px;
    margin-top: 0px;
  }
`;

const Subtitle = styled.h2`
  font-size: 22px;
  color: #333333;
  font-weight: normal;
  font-family: 'Source Sans Pro';
  text-align: center;
  padding-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 17px;
    text-align: center;
    margin-top: 10px;
    padding-bottom: 10px;
  }
`;

const EmailInput = styled.input`
  border: 2px solid #111d57;
  border-radius: 2px;
  font-size: 18px;
  background-color: #ffffff;
  text-align: center;
  padding-left: 20px;
  font-family: 'Source Sans Pro';
  width: 300px;
  height: 45px;
  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 5px;
    width: 185px;
    height: 35px;
  }
`;

const Button = styled.button`
  background-color: #284389;
  color: white;
  cursor: pointer;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  padding-left: 20px;
  width: 190px;
  height: 50px;
  margin-left: -1px;
  &:hover {
    background-color: #111d57;
  }
  @media (max-width: 768px) {
    padding-left: 10px;
    font-size: 15px;
    width: 145px;
    height: 48px;
    margin-left: -2px;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 18px;
  font-family: 'Source Sans Pro';
`;

interface ConnectPGEProps {
  advanceStage: (nextStage: string) => void;
}

const ConnectPGE = ({ advanceStage }: ConnectPGEProps) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  
  const images = [background1, background2, background3, background4, background5, background6];

  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');  // Clear any error after a delay, adjust or remove as needed
    }, 3000);
    return () => clearTimeout(timer);
  }, [error]);

  useEffect(() => {
    // Function to change the current image index
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    // Set an interval to change the image every 5 seconds
    const interval = setInterval(changeImage, 5000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [images.length]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError("");
  };

  const handleEmailSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    const domain = email.split('@')[1];
    try {
      const lookup = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
      const result = await lookup.json();
      if (!result.Answer) {
        setError("Please enter a valid email address.");
        return;
      }
    } catch (e) {
      console.error("DNS lookup failed:", e);
      setError("DNS error occurred. Please try again.");
      return;
    }
    const recordId = localStorage.getItem("record_id");

    if (recordId) {
      const { error } = await supabase
        .from("interest-users")
        .update({ email: email })
        .eq("id", parseInt(recordId))
        .select();

      if (error) {
        console.error("Error updating data:", error.message);
        setError("Failed to save email.");
      } else {
        mixpanel.track("Entered email")
        advanceStage("thankYou");
        navigate(`/business-or-household?email=${encodeURIComponent(email)}`);
      }
    }
  };

  return (
    <>
    <Helmet>
      <title>EcoTrove | Get Quote</title>
      <meta name="description" content="Receive a personalized savings quote to replace your utility bills now." />
      <meta name="keywords" content="affordable clean energy, accessible clean energy, clean energy subscription, lower energy bills, EcoTrove, green power" />
      <GlobalStyles /> {/* Include global styles */}
      <PixelTracking />
    </Helmet> 
    <main>
      <OuterContainer>
        <BackgroundContainer>
          {images.map((image, index) => (
            <BackgroundImage 
              key={index}
              image={image}
              isVisible={index === currentImageIndex}
            />
          ))}
        </BackgroundContainer>
        <PGEWhitespace>
          <Title>Link PG&E with EcoTrove<br />You are eligible to save up to 10% on your energy bills</Title>
          <Subtitle>
            Enter your email to connect your PG&E account with EcoTrove and receive a personalized quote to replace your PG&E bills with guaranteed fixed prices.  <br />
            We will route you to pge.com to securely estimate your savings and your data will be deleted afterwards.
          </Subtitle>
          <form onSubmit={handleEmailSubmit} style={{ display: 'flex', alignItems: 'center' }}>
            <EmailInput
              type="email"
              placeholder="Your email address…"
              value={email}
              onChange={handleEmailChange}
            />
            <Button type="submit">Go to pge.com</Button>
          </form>
          <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', gap: '35px' }}>

          </div>
          {error && <ErrorText>{error}</ErrorText>}
          <PlasmicRootProvider loader={PLASMIC}>
            <PlasmicComponent component="Hero_Footer" />
          </PlasmicRootProvider>
        </PGEWhitespace>
      </OuterContainer>
      </main>
    </>
  );
};

export default ConnectPGE;