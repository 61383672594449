import styled from "styled-components";
import Navbar from "../components/Navbar";
import { Helmet } from 'react-helmet';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Container = styled.section`
  margin-top: -50px;
  padding-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Stack items vertically */
  min-height: 98vh; /* Full height of the screen */
  background-color: #e6f7ff; /* Specified background color */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; // Center align the buttons
  gap: 40px; // 40px spacing between buttons
  width: 50%;
  max-width: 600px;
  @media (max-width: 768px) {
    justify-content: center;
    gap: 20px; // 20px spacing on mobile
    width: 95%;
  }
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: bold;
  color: #111d57;
  line-height: 1.5;
  font-family: "DM Sans";
  margin-bottom: 30px; /* Padding between title and buttons */
  margin-top: -135px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Subtitle = styled.p`
  color: #333333;
  font-size: 22px;
  font-family: "DM Sans";
  text-align: center;
  width: 65%;
  padding-top: 30px;
  @media (max-width: 768px) {
    font-size: 18px;
    width: 95%;
  }
`;

const Button = styled.button`
  width: 200px; /* Fixed width for both buttons */
  padding: 18px 30px;
  border: 3px solid #111d57;
  border-radius: 5px;
  color: white;
  margin-top: -100px;
  cursor: pointer;
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 0 10px; /* Adjusted margin for side-by-side buttons */
  &:hover {
    background-color: #111d57;
  }
`;

const HouseholdButton = styled(Button)`
  background-color: #284389;
`;

const BusinessButton = styled(Button)`
  background-color: #e6f7ff;
  color: #111d57;
  border: 3px solid #111d57;

  &:hover {
    background-color: #111d57;
    color: white;
}`;

const BusinessOrHousehold = () => {
  const currentUrl = window.location.search;
  // Extract query parameters from the URL
  const urlParams = new URLSearchParams(currentUrl);
  const email = urlParams.get("email");
  if (email == null) {
    throw new Error("missing email");
  }
  const redirectToPGE = (email: string, savingsType: string) => {
    const clientId = "8fc92047bb9b446d9ed5de3de21e8600";
    const responseType = "code";
    const authUrl = new URL("https://sharemydata.pge.com/myAuthorization");
    let testing = "0";
    if (process.env.REACT_APP_WEBSITE_URL !== "https://ecotrove.com") {
      testing = "1";
    }
    let skipQuote = "0";
    if (savingsType === "business") {
      skipQuote = "1";
    }
    const state = testing + skipQuote + email;
    authUrl.search = new URLSearchParams({
      client_id: clientId,
      redirect_uri: "https://ecotrove.com/pge-oauth-redirect",
      response_type: responseType,
      state: state,
    }).toString();
    window.location.href = authUrl.href;
  };

  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
    <PixelTracking />
   </Helmet> 

    <main>
    <div>
      <Navbar />
      <Container>
        <Title>Are you a household or a business?</Title>
        <ButtonContainer>
          <HouseholdButton onClick={() => redirectToPGE(email, "household")}>
            Household
          </HouseholdButton>
          <BusinessButton onClick={() => redirectToPGE(email, "business")}>
            Business 
          </BusinessButton>
        </ButtonContainer>
        <Subtitle>We will redirect you to pge.com to log in to your Pacific Gas & Electric account to authorize EcoTrove to securely estimate your savings opportunity. <br /><br /> This process will take less than a minute and your data will be deleted afterwards.</Subtitle>
      </Container>
    </div>
    </main>
  </>
  );
};

export default BusinessOrHousehold;
