import styled, { keyframes } from "styled-components";
import { supabase } from "../supabaseClient";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
import mixpanel from "mixpanel-browser";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import wattson_icon from "../images/wattson.png"; // New image import
import { Helmet } from 'react-helmet';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e6f7ff;
  width: 100%;
  min-height: 160vh;
  overflow-x: hidden;
`;

const NewQuoteSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 50px 50px 50px;
  text-align: center;
  width: 90vw;
  background-color: #e6f7ff;
`;

const QuoteTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
  color: #111d57;
  line-height: 1.5;
  padding-top: 5px;

  @media (max-width: 768px) {
    font-size: 20px;
    padding-top: -35px;
    padding-bottom: -25px;
    line-height: 1.5;
  }
`;

const QuoteSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
    padding: 0px;
  }
`;

const QuoteGraph = styled.div`
  padding: 20px;
  width: 48%;
  display: inline-block;
  vertical-align: top;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
`;

const QuoteExplainer = styled.div`
  padding: 40px;
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
`;

const QuoteSubtitle = styled.h2`
  color: #666;
  font-size: 22px;
  line-height: 1.5;
  padding-top: 2px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding-top: 0px;
    line-height: 1.5;
  }
`;

const QuoteText = styled.h3`
  color: #666;
  font-size: 18px;
  line-height: 1.5;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const QuoteTagline = styled.div`
  padding: 5px;
  width: 85%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface SpacerProps {
  height?: string;
  width?: string;
}

const Spacer = styled.div<SpacerProps>`
  height: ${({ height }) => height || "1rem"};
  width: ${({ width }) => width || "1rem"};
`;

const Button = styled.button`
  padding: 15px 15px;
  background-color: #284389;
  border: 3px solid #111d57;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: "Forum";
  font-size: 18px;
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #111d57;
    font-size: 18px;
    padding: 5px 5px;
  }

  @media (max-width: 768px) {
    padding: 10px 10px;
    font-size: 15px;
    height: 60px;
    &:hover {
      background-color: #111d57;
      font-size: 15px;
      padding: 5px 5px;
    }
  }
`;

const StartNowButton = styled(Button)`
  background-color: #284389;
`;

const StartLaterButton = styled(Button)`
  background-color: #e6f7ff;
  color: #111d57;
  border: 3px solid #111d57;

  &:hover {
    background-color: #111d57;
    color: white;
  }

  @media (max-width: 768px) {
    &:hover {
      background-color: #111d57;
      font-size: 15px;
      color: white;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  width: 50%;
  margin-top: 30px;
  
  @media (max-width: 768px) {
    justify-content: center;
    gap: 20px;
    width: 95%;
  }
`;

// Keyframes for the icon animations
const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

// New styled component for the Wattson icon
const WattsonIconWrapper = styled.div`
  position: fixed;
  bottom: 10px; 
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  width: 190px;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: 
    ${slideUp} 0.5s ease-out 3s forwards, // Slide up animation after 3s delay
    ${bounce} 0.5s ease-in-out 3.5s 2; // Bounce twice after sliding up
  animation-fill-mode: forwards; // Ensure final state is preserved
  transition: transform 0.3s ease; // Smooth transition for hover effect

  &:hover {
    transform: scale(1.2); // Increase size on hover
  }

  @media (max-width: 768px) {
    width: 110px;
    height: 150px;
    bottom: 10px; 
    right: 10px;
  }
`;

const WattsonIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease; // Smooth transition for hover effect

  &:hover {
    transform: scale(1.2); // Increase size on hover
  }
`;

const ShowQuote = () => {

  //TODO: extrapolate tracking logic
  interface TrackingFlags {
    unexpTokenFetch: boolean;
    generateQuote: boolean;
    showQuote: boolean;
    docusign: boolean;
    quoteError: boolean;
    insufficientDataForQuote: boolean;
    unexpSubmitEmail: boolean;
    [key: string]: boolean;
  }
  
  const [trackingFlags, setTrackingFlags] = useState<TrackingFlags>({
    unexpTokenFetch: false,
    generateQuote: false,
    showQuote: false,
    docusign: false,
    quoteError: false,
    insufficientDataForQuote: false,
    unexpSubmitEmail: false
  });

  const trackEventOnce = (eventName: string) => {
    console.log("Tracking: ", eventName)
    console.log("Tracking Flags: ", trackingFlags)
    if (!trackingFlags[eventName]) {
      mixpanel.track(eventName);
      setTrackingFlags({ ...trackingFlags, [eventName]: true });
    }
  };

  trackEventOnce("showQuote");
  const urlParams = new URLSearchParams(window.location.search);
  const monthlyQuote = parseFloat(urlParams.get("monthlyQuote") as string);
  const averageBill = parseFloat(urlParams.get("averageBill") as string);
  // const highestBill = parseFloat(urlParams.get("highestBill") as string);
  const email = urlParams.get("email") as string;

    const loadCustomizeQuote = () => {
      if (process.env.REACT_APP_TESTING !== "true") {
        console.log("accepted quote");
        const supabasePromise = supabase
          .from("user-profiles")
          .update({
            accepted_quote: true,
          })
          .eq("email", email);
        console.log("done uploading quote to supabase");
      
        supabasePromise.then(({ error }) => {
          if (error) {
            console.error('Error:', error.message);
          } else {
            console.log('Successfully logged complete user to Supabase.');
          }
        });
      }
      const params = new URLSearchParams();
      params.append("monthlyQuote", monthlyQuote.toString());
      params.append("averageBill", averageBill.toString());
      params.append("email", email);
      // Construct the full URL
      const showQuoteUrl = `/customize-quote?${params.toString()}`;
      // Use the navigate function to navigate to the constructed URL
      navigate(showQuoteUrl);
    };

  const quoteURL = window.location.href;

  if (process.env.REACT_APP_TESTING !== "true") {
    const supabasePromise = supabase
    .from("user-profiles")
    .update({ quote_url: quoteURL })
    .eq("email", email);
    supabasePromise.then(({ error }) => {
      if (error) {
        console.error('Error:', error.message);
      } else {
        console.log('Successfully uploaded to Supabase.');
      }
    });
  };

  const adjustedAverageBill = averageBill * 1; // Updated line
  const plasmicAverageBill = `$${(adjustedAverageBill * 12.5).toFixed(0)} /year`;
  const plasmicQuote = `$${(monthlyQuote * 12).toFixed(0)} /year`;
  const plasmicDifference = `Save $${((adjustedAverageBill - monthlyQuote) * 12).toFixed(0)}`;
  const navigate = useNavigate();

  const handleWattsonIconClick = () => {
    window.open('mailto:info@ecotrove.com?subject=Question%20about%20my%20quote', '_blank');
  };

  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
    <PixelTracking />
   </Helmet> 

    <main>
    <Container>
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent component="homepage_topbar" />
      </PlasmicRootProvider>
      <NewQuoteSection>
        <>
          <QuoteTagline>
            <QuoteTitle>Congratulations! Your fixed price to replace PG&E bills is ${monthlyQuote}/month</QuoteTitle>
            <QuoteSubtitle>Use all the electricity and gas you need, and save a guaranteed ${(((adjustedAverageBill) - monthlyQuote) * 12).toFixed(0)} or {(-(((monthlyQuote) / adjustedAverageBill) - 1) * 100).toFixed(1)}% in the next year with EcoTrove.</QuoteSubtitle>
            <Spacer height="1rem" />
          </QuoteTagline>

          <ButtonContainer>
            <StartNowButton onClick={loadCustomizeQuote}>
              Start Subscription
            </StartNowButton>
            <StartLaterButton onClick={() => navigate(`/request-emailed-quote`)}>
              Email Quote for Later
            </StartLaterButton>
          </ButtonContainer>

          <QuoteSection>
            <QuoteGraph>
              <PlasmicRootProvider loader={PLASMIC}>
                <PlasmicComponent
                  component="CustomQuoteGraph"
                  componentProps={{
                    plasmicAverageBill,
                    plasmicQuote,
                    plasmicDifference,
                  }}
                />
              </PlasmicRootProvider>
            </QuoteGraph>

            <QuoteExplainer>
              <QuoteText>
                PG&E bills are 27%+ higher this year. Lock in your fixed ${monthlyQuote} monthly price now and save ${(((adjustedAverageBill) - monthlyQuote) * 12).toFixed(0)} (or {(-(((monthlyQuote) / adjustedAverageBill) - 1) * 100).toFixed(1)}%) next year. Cancel anytime if you're not 100% satisfied. <br /><br />
                You will stop paying PG&E and authorize EcoTrove to pay  your PG&E electricity and gas (if applicable) bills, secure discounts, and request shifts to renewable energy on your behalf. <br /><br />
                EcoTrove will bill you in ${monthlyQuote} (incl. taxes) monthly installments for the next 12 months and cover your next 12 PG&E bills. Unless you select a later start date on the next step, your subscription will begin tomorrow.
              </QuoteText>
            </QuoteExplainer>
          </QuoteSection>

          <Spacer height="1rem" />

          <PlasmicRootProvider loader={PLASMIC}>
            <PlasmicComponent component="Quote_Guarantee" />
          </PlasmicRootProvider>

          <Spacer height="1rem" />

          <ButtonContainer>
            <StartNowButton onClick={loadCustomizeQuote}>
              Start Subscription
            </StartNowButton>
            <StartLaterButton onClick={() => navigate(`/request-emailed-quote`)}>
              Email Quote for Later
            </StartLaterButton>
          </ButtonContainer>
        </>
      </NewQuoteSection>

      <WattsonIconWrapper onClick={handleWattsonIconClick}>
        <WattsonIcon src={wattson_icon} alt="Wattson Icon" />
      </WattsonIconWrapper>
      <Footer />
    </Container>
    </main>
  </>
  );
};

export default ShowQuote;
