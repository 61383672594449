import styled from "styled-components";
import Navbar from "../components/AccountNavbar";
import FooterAccount from "../components/FooterAccount";
import { useNavigate } from 'react-router-dom';
import { supabase } from "../supabaseClient";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import background1 from '../images/background1.jpeg'; // Import the background image

const PageContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
    background: url(${background1}) no-repeat center center fixed;
    background-size: cover;
    height: 240vh;
    & > div {
      background: rgba(255, 255, 255, 0.5);
    }
  }
`;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220vh; /* Increased by 100vh */
    width: 100%;
    background-color: white;
  }
`;

const MobileIframeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150vh; /* Increased by 100vh */
  background-color: white;

  iframe {
    width: 90%;
    height: 150vh; 
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  padding-top: 25px;

  @media (min-width: 769px) {
    width: 70%;
  }
`;

const BackgroundImage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${background1}) no-repeat center center fixed;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 769px) {
    width: 30%;
  }
`;

const DesktopIframeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 95%;
  height: 90%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Heading = styled.h1`
  text-align: center;
  color: #253D80;
`;

const RoundedRectangle = styled.div`
  border: 2px solid #253D80;
  border-radius: 15px;
  padding: 1rem;
  margin: 1rem 0;
  width: 80%;
`;

const QuoteDetailsTitle = styled.h2`
  color: #253D80;
  font-size: 1.2rem;
  text-align: center;
`;

const ServiceDetailsTitle = styled.h2`
  color: #253D80;
  font-size: 1.2rem;
  text-align: center;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const LogoutButton = styled.button`
  font-size: 1rem;
  color: white;
  background-color: #80AA7B;
  border: none;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #253D80;
  }
`;
const generateEmbedToken = (userId: string) => {
  const jwt = require("jsonwebtoken");
  const METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY as string;

  const payload = {
    resource: { dashboard: 34 },
    params: {
      "user_id": userId
    },
    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
  };

  const token = jwt.sign(payload, METABASE_SECRET_KEY);
  return token;
};

const UserDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [serviceAccounts, setServiceAccounts] = useState<any>(null);
  const [emissionReduction, setEmissionReduction] = useState<any>(null);
  const [CCA, setCCA] = useState<string>("Coming Soon!");
  const [iframeSrc, setIframeSrc] = useState<string>("");
  const METABASE_SITE_URL = "https://ecotrove.metabaseapp.com";

  useEffect(() => {
    const fetchUserProfileAndServiceAccounts = async () => {
      try {
        setLoading(true);
        const { data: { user } } = await supabase.auth.getUser();

        if (!user) {
          alert("Please log in to view this screen!");
          navigate('/login-page');
          return;
        }

        const { data: userProfileData, error: userProfileError } = await supabase
          .from('user-profiles')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (userProfileError) {
          throw userProfileError;
        }
        setUserProfile(userProfileData);

        const { data: serviceAccountData, error: serviceAccountError } = await supabase
          .from('service-accounts')
          .select('*')
          .eq('user_id', user.id);

        if (serviceAccountError) {
          throw serviceAccountError;
        }

        setServiceAccounts(serviceAccountData);

        // Calculate emission reduction from service-accounts
        let totalRenewableIncrease = 0;
        let accountsWithRenewableIncrease = 0;

        serviceAccountData.forEach((account: any) => {
          if (account.renewable_increase !== null && account.renewable_increase !== undefined) {
            totalRenewableIncrease += account.renewable_increase;
            accountsWithRenewableIncrease += 1;
          }
        });

        if (accountsWithRenewableIncrease > 0) {
          const averageRenewableIncrease = totalRenewableIncrease / accountsWithRenewableIncrease;
          const roundedIncrease = (Math.ceil(averageRenewableIncrease + 100) / 100).toFixed(1);
          setEmissionReduction(`${roundedIncrease}x`);
        } else {
          setEmissionReduction("Coming Soon!");
        }

        // Set CCA provider by checking all rows in the 'cca_name_manual' column
        let ccaProvider = "Coming Soon!";
        for (const account of serviceAccountData) {
          if (account.cca_name_manual) {
            ccaProvider = account.cca_name_manual;
            break;
          }
        }

        setCCA(ccaProvider);

        // Set iframe src for metabase
        const userId = userProfileData.user_id;
        const metabaseToken = generateEmbedToken(userId);
        const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + metabaseToken +
          "#bordered=true&titled=true";;
        setIframeSrc(iframeUrl);

      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfileAndServiceAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      localStorage.clear();
      navigate('/');
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>EcoTrove | Lower Energy Bills with Fixed Prices</title>
        <meta name="description" content="EcoTrove replaces your utility bills with a fixed-price clean energy subscription up to 10% cheaper. Discover how you can pay less for greener, predictable, power." />
        <meta name="keywords" content="fixed-price energy, clean energy subscription, lower energy bills, EcoTrove, green power" />
      </Helmet>
      <Navbar />
      <PageContainer>
        <ContentContainer>
          <Heading>Welcome {userProfile?.name || ''}</Heading>
          <RoundedRectangle>
            <QuoteDetailsTitle>Quote Details</QuoteDetailsTitle>
            <p><BoldSpan>Monthly Quote:</BoldSpan> ${userProfile?.monthly_quote}/month</p>
            <p><BoldSpan>Start Date:</BoldSpan> {formatDate(userProfile?.start_date)}</p>
            <p><BoldSpan>Increase in renewable electricity:</BoldSpan> {emissionReduction}</p>
            <p><BoldSpan>Your renewable electricity provider:</BoldSpan> {CCA}</p>
          </RoundedRectangle>
          <RoundedRectangle>
            <ServiceDetailsTitle>Service Details</ServiceDetailsTitle>
            <p><BoldSpan>Email:</BoldSpan> {userProfile?.email}</p>
            <p><BoldSpan>Covered service addresses:</BoldSpan></p>
            {/* Iterate over service accounts and display each service address */}
            {serviceAccounts && serviceAccounts.map((account: any, index: number) => (
              <div key={index}>
                <p><BoldSpan>{index + 1}.</BoldSpan> {account.street_address + " " + account.city + ", " + account.state + " " + account.zip_code}</p>
                {/* Add additional details as needed */}
              </div>
            ))}
          </RoundedRectangle>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </ContentContainer>
        <BackgroundImage>
          <DesktopIframeContainer>
            <iframe
              src={iframeSrc}
              frameBorder="0"
              width="100%"
              height="100%"
              allowTransparency
              title="Dashboard"
            ></iframe>
          </DesktopIframeContainer>
        </BackgroundImage>
        <MobileContainer>
          <MobileIframeContainer>
            <iframe
              src={iframeSrc}
              frameBorder="0"
              width="90%"
              height="100%"
              allowTransparency
              title="Dashboard"
            ></iframe>
          </MobileIframeContainer>
        </MobileContainer>
      </PageContainer>
      <FooterAccount />
    </>
  );
};

export default UserDashboard;
