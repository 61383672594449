import { constructFullApiURL } from "./constructFullApiUrl";
export const handleStartStripe = async (email: string, subscriptionQuote: number, startDate: Date) => {
    try {
        const apiURL = constructFullApiURL(`/stripe-url`);
        const response = await fetch(apiURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            // Include any necessary data in the request body
            subscriptionQuote: subscriptionQuote,
            email: email,
            startDate: startDate,
          }),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch Stripe URL');
        }
        const data = await response.json();
        const stripeURL = data.stripeURL;
        // Redirect the user to the stripe URL
        window.location.href = stripeURL;
      } catch (error) {
        console.error('Error starting now:', error);
        // Handle error, e.g., display an error message to the user
      }
}