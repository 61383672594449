import styled from "styled-components";
import Navbar from "../components/Navbar";
import AboutUsTopSection from "../components/AboutUsTopSection";
import AboutUsTeam from "../components/AboutUsTeam";
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`;

const AboutUs = () => {
  return (
    <>
     <GlobalStyles /> {/* Include global styles */}
    <Helmet>
      <title>EcoTrove | About Us</title>
      <meta name="description" content="EcoTrove makes clean energy accessible and affordable. We believe you deserve to live in comfort without depending on fossil fuels or sacrificing your financial stability" />
      <meta name="keywords" content="affordable clean energy, accessible clean energy, Ali Sarilgan, Ozge Islegen-Wojdyla, clean energy subscription, lower energy bills, EcoTrove, green power" />
    </Helmet> 

    <main>
    <PageContainer>
      <Navbar />
      <AboutUsTopSection></AboutUsTopSection>
      <AboutUsTeam></AboutUsTeam>
      <Footer></Footer>
    </PageContainer>
    </main>
  </>
  );
};

export default AboutUs;
