import styled from "styled-components";
import Navbar from "../components/AccountNavbar";
import FooterAccount from "../components/FooterAccount";
import { useNavigate } from 'react-router-dom';
import { supabase } from "../supabaseClient";
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import background3 from '../images/background3.jpeg'; // Import the background image

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;
  
  @media (max-width: 768px) {
    background: url(${background3}) no-repeat center center fixed;
    background-size: cover;
    & > div {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  padding-top: 5px;

  @media (min-width: 769px) {
    width: 70%;
  }
`;

const BackgroundImage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${background3}) no-repeat center center fixed;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 769px) {
    width: 30%;
  }
`;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65vh;
    width: 100%;
    background-color: white;
  }
`;

const MobileIframeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 80%;
  background-color: white;

  iframe {
    width: 90%;
    height: 100%;
  }
`;

const DesktopIframeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 70%;
  height: 45%;
  margin-top: -100px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Heading = styled.h1`
  text-align: center;
  color: #253D80;
`;

const RoundedRectangle = styled.div`
  border: 2px solid #253D80;
  border-radius: 15px;
  padding: 1rem;
  margin: 1rem 0;
  width: 80%;
  text-align: center;
`;

const DetailsTitle = styled.h2`
  color: #253D80;
  font-size: 1.2rem;
  text-align: center;
`;

const Paragraph = styled.p`
  text-align: center;
`;

const Button = styled.button`
  font-size: 1rem;
  color: white;
  background-color: #80AA7B;
  border: none;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #253D80;
  }
`;

const SaveMore = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [iframeSrc, setIframeSrc] = useState<string>('');
  const METABASE_SITE_URL = "https://ecotrove.metabaseapp.com";

  const generateEmbedToken = (userId: string) => {
    const jwt = require("jsonwebtoken");
    const METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY as string;
  
    const payload = {
      resource: { dashboard: 137 },
      params: {
        "user_id": userId
      },
      exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };
  
    const token = jwt.sign(payload, METABASE_SECRET_KEY);
    return token;
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);

        const { data: { user } } = await supabase.auth.getUser();
        console.log("user return val", user);
        
        if (user === null || user === undefined || !user) {
          alert("Please log in to view this screen!");
          navigate('/login-page');
        }

        const { data, error } = await supabase
          .from('user-profiles')
          .select('*')
          .eq('user_id', user?.id)
          .single();

        if (error) {
          throw error;
        }
        setUserProfile(data);

        // Set iframe src
        const userId = data.user_id;
        const metabaseToken = generateEmbedToken(userId);
        const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + metabaseToken + "#bordered=true&titled=true";;
        setIframeSrc(iframeUrl);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(userProfile);

  if (loading) {
    return (
      <div>Loading...</div>
    );
  }

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      localStorage.clear();
      navigate('/');
      window.scrollTo(0, 0); 
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleButtonClick = (url: string) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null;
      newWindow.focus();
    } else {
      alert('Failed to open pop up. Please check your browser settings.');
    }
  };

  return (
    <>
      <GlobalStyles /> 
      <Helmet>
        <title>EcoTrove | Lower Energy Bills with Fixed Prices</title>
        <meta name="description" content="EcoTrove replaces your utility bills with a fixed-price clean energy subscription up to 10% cheaper. Discover how you can pay less for greener, predictable, power." />
        <meta name="keywords" content="fixed-price energy, clean energy subscription, lower energy bills, EcoTrove, green power" />
      </Helmet>
      <Navbar />
      <PageContainer>
        <ContentContainer>
          <Heading>More Ways to Save</Heading>
          <RoundedRectangle>
            <DetailsTitle>Refer A Friend for $30</DetailsTitle>
            <Paragraph>Refer friends to join EcoTrove and claim a $30 bill credit for each friend that joins.</Paragraph>
            <Button onClick={() => handleButtonClick("mailto:yourfriend@enter.their.email?subject=%20Earn%20%2430%20and%20Save%20On%20Your%20Utility%20Bills&body=Hey!%20%0A%0AI%20wanted%20to%20share%20a%20great%20new%20way%20to%20save%20money%20on%20your%20utility%20bills%20and%20earn%20an%20extra%20%2430.%20I%27ve%20been%20using%20EcoTrove.com%20to%20manage%20my%20utility%20bills%20and%20they%27ve%20been%20great.%20Instead%20of%20paying%20PG%26E%20for%20wildly%20fluctuating%20power%20bills%2C%20you%20can%20pay%20EcoTrove%20the%20same%20fixed-price%20every%20month%20for%20the%20next%2012%20months.%20%0A%0AThey%20save%20you%20up%20to%2010%25%20on%20your%20bills%2C%20switch%20you%20to%20clean%20power%20and%20pay%20all%20your%20PG%26E%20bills%20on%20your%20behalf%20-%20no%20matter%20how%20much%20it%20actually%20costs!%0A%0AIf%20you%20sign%20up%20and%20enter%20my%20email%20address%20after%20you%20are%20done%2C%20you%20will%20get%20%2430%20off%20your%20next%20bill.%20")}>Refer a Friend</Button>
          </RoundedRectangle>
          <RoundedRectangle>
            <DetailsTitle>Send Us Your Bill for $15</DetailsTitle>
            <Paragraph>Take a picture of your latest PGE bill or download the PDF from pge.com, and send it to EcoTrove to allow us to secure further savings on your behalf.</Paragraph>
            <Button onClick={() => handleButtonClick("mailto:info@ecotrove.com?subject=Latest PG&E Bill Document&body=Directions: Take a picture of the first page of your latest PGE bill or download the PDF from pge.com, and attach it to this email before sending it to EcoTrove.%0A%0AThis will allow us to secure further savings on your behalf. You can find information on where to locate your latest PGE bill on your account on: https://www.pge.com/en/account/billing-and-assistance.html")}>Send Bill to EcoTrove</Button>
          </RoundedRectangle>
          <RoundedRectangle>
            <DetailsTitle>Help Us Improve for $25</DetailsTitle>
            <Paragraph>Speak to an EcoTrove team member about your experience to claim a $25 bill credit and help us improve.</Paragraph>
            <Button onClick={() => handleButtonClick("https://calendly.com/ali-sarilgan/ecotrove-call")}>Schedule Call</Button>
          </RoundedRectangle>
          <Button onClick={handleLogout}>Logout</Button>
        </ContentContainer>
        <BackgroundImage>
          <DesktopIframeContainer>
            <iframe
              src={iframeSrc}
              frameBorder="0"
              width="100%"
              height="100%"
              allowTransparency
              title="Dashboard"
            ></iframe>
          </DesktopIframeContainer>
        </BackgroundImage>
        <MobileContainer>
          <MobileIframeContainer>
            <iframe
              src={iframeSrc}
              frameBorder="0"
              width="90%"
              height="100%"
              allowTransparency
              title="Dashboard"
            ></iframe>
          </MobileIframeContainer>
        </MobileContainer> 
        </PageContainer>     
        <FooterAccount/>
    </>
  );
};

export default SaveMore;
