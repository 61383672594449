import { supabase } from "./supabaseClient"
import {constructFullApiURL} from "./constructFullApiUrl";
export const handleStartDocusign = async (email: string, monthlyQuote: number) => {

if (process.env.REACT_APP_TESTING !== "true") {
  console.log("uploading quote to supabase");
  const { error: monthlyQuoteError } = await supabase
    .from("user-profiles")
    .update({
      monthly_quote: monthlyQuote,
    })
    .eq("email", email);
  console.log("done uploading quote to supabase");

  if (monthlyQuoteError) {
    console.log("Error updating monthly quote: ", monthlyQuoteError);
  }
}
  

  const { data, error: userProfileError } = await supabase
  .from("user-profiles")
  .select("name, start_date")
  .eq("email", email)
  .single();

  if (userProfileError) {
    console.log(userProfileError);
  }

  console.log("user profile data: ", data)

  const subscriberName = data?.name;
  const subscriberStartDate = data?.start_date; 

  const { data: serviceAccountData, error: serviceAccountError } = await supabase
  .from("service-accounts")
  .select("street_address, city, zip_code, service_account")
  .eq("email", email)

  console.log(data)

  const serviceAccounts: { service_account: string, city: string, street_address: string }[] = [];
  if (serviceAccountError || serviceAccountData == null) {
    throw Error("error fetching service account data for docusign")
  } else {
    // serviceAccountData will be an array of objects if there are multiple rows
    if (serviceAccountData) {
      // Iterate over each row in the result
      serviceAccountData.forEach((row) => {
        serviceAccounts.push({ service_account: row?.service_account, city: row?.city, street_address: row?.street_address }); // Each row object containing fields: street_address, city, zip_code, service_account, start_date
      });
    } else {
      console.log("No data found."); // This will execute if there are no rows matching the query
    }
  }

  const subscriberAddress = serviceAccountData[0]?.street_address;
  const subscriberCity = serviceAccountData[0]?.city;
  const subscriberZip = serviceAccountData[0]?.zip_code;

  try {
    const apiURL = constructFullApiURL("/docusign-url");
    // Make an HTTP request to your backend to fetch the DocuSign URL
    const response = await  fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // Include any necessary data in the request body
        name: subscriberName,
        email: email,
        subscriptionStartDate: subscriberStartDate,
        subscriptionQuote: monthlyQuote,
        subscriberAddress: subscriberAddress,
        subscriberCity: subscriberCity,
        subscriberZip: subscriberZip,
        subscriberServiceAccounts: serviceAccounts,
      }),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch DocuSign URL');
    }
    const data = await response.json();
    const docusignURL = data.signingURL;

    // Redirect the user to the DocuSign URL
    window.location.href = docusignURL;
  } catch (error) {
    console.error('Error starting now:', error);
    // Handle error, e.g., display an error message to the user
  }
};