import styled from "styled-components";
import Navbar from "../components/AccountNavbar";
import FooterAccount from "../components/FooterAccount";
import { useNavigate } from 'react-router-dom';
import { supabase } from "../supabaseClient";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import background2 from '../images/background2.jpeg'; // Import the background image

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;
  
  @media (max-width: 768px) {
    background: url(${background2}) no-repeat center center fixed;
    background-size: cover;
    & > div {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  padding-top: 5px;

  @media (min-width: 769px) {
    width: 70%;
  }
`;

const BackgroundImage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${background2}) no-repeat center center fixed;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 769px) {
    width: 30%;
  }
`;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65vh;
    width: 100%;
    background-color: white;
  }
`;

const MobileIframeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 80%;
  background-color: white;

  iframe {
    width: 90%;
    height: 100%;
  }
`;

const DesktopIframeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  height: 43%;
  margin-top: -300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Heading = styled.h1`
  text-align: center;
  color: #253D80;
`;

const RoundedRectangle = styled.div`
  border: 2px solid #253D80;
  border-radius: 15px;
  padding: 1rem;
  margin: 1rem 0;
  width: 80%;
`;

const DetailsTitle = styled.h2`
  color: #253D80;
  font-size: 1.2rem;
  text-align: center;
`;

const BoldParagraph = styled.p`
  font-weight: bold;
`;

const InputContainer = styled.div`
  margin: 1rem 0;
`;

const InputBox = styled.input`
  border: 1px solid gray;
  border-radius: 8px;
  padding: 0.5rem;
  width: 75%;
`;

const Button = styled.button`
  font-size: 1rem;
  color: white;
  background-color: #80AA7B;
  border: none;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #253D80;
  }
`;

const generateEmbedToken = (userId: string) => {
  const jwt = require("jsonwebtoken");
  const METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY as string;

  const payload = {
    resource: { dashboard: 105 },
    params: {
      "user_id": userId
    },
    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
  };

  const token = jwt.sign(payload, METABASE_SECRET_KEY);
  return token;
};

const ManageAccount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [iframeSrc, setIframeSrc] = useState<string>('');
  const [cancelledSubscription, setCancelledSubscription] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [originalName, setOriginalName] = useState<string>('');
  const [originalEmail, setOriginalEmail] = useState<string>('');
  const METABASE_SITE_URL = "https://ecotrove.metabaseapp.com";

  // State variables to track changes
  const [updatedName, setUpdatedName] = useState<string>('');
  const [updatedEmail, setUpdatedEmail] = useState<string>('');
  const [updatedPassword, setUpdatedPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);

        const { data: { user } } = await supabase.auth.getUser();
        
        if (user === null || user === undefined || !user) {
          alert("Please log in to view this screen!");
          navigate('/login-page');
        }

        const { data: userProfile, error: userProfileError } = await supabase
          .from('user-profiles')
          .select('*')
          .eq('user_id', user?.id)
          .single();

        if (userProfileError) {
          throw userProfileError;
        }
        setUserProfile(userProfile);
        if (userProfile) {
          setOriginalName(userProfile.name);
          setOriginalEmail(userProfile.email);

          // Set iframe src
          const userId = userProfile.user_id;
          const metabaseToken = generateEmbedToken(userId);
          const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + metabaseToken +
            "#bordered=true&titled=true";;
          setIframeSrc(iframeUrl);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //IN ORDER TO PULL ANY INFORMATION FROM USER PROFILE, do userProfile?.column_name like userProfile?.monthly_quote or userProfile?.email

  //show loading screen until user data is pulled from supabase-this should be instant
  if (loading) {
    return (
      <div>Loading...</div>
    );
  }

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      localStorage.clear();
      navigate('/');
      window.scrollTo(0, 0); 
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const { data: cancellationData, error: cancellationError } = await supabase
      .from('cancellation-requests')
      .insert({user_id: userProfile?.user_id, email: userProfile?.email});

      setCancelledSubscription(true); //I set a boolean here but we don't do anything with it right now
      console.log(cancelledSubscription);
      console.log(cancellationData);
      if (cancellationError) {
        throw cancellationError;
      }
      alert("We have received your request to cancel your subscription.");
    } catch (error) {
      alert("Error canceling subscription, please email info@ecotrove.com");
    }
  };

  // Function to handle changes in input fields
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const validateEmail = async (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!emailRegex.test(email)) {
      return false;
    }

    const domain = email.split('@')[1];
    try {
      const lookup = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
      const result = await lookup.json();
      return result.Answer !== undefined;
    } catch (e) {
      console.error("DNS lookup failed:", e);
      return false;
    }
  };

  // Function to save profile and detect changes
  const handleSaveProfile = async () => {
    try {
      setErrorMessage("");
      // Check which fields were updated
      const updatedFields = [];
      if (updatedName !== originalName && updatedName !== "" && updatedName !== undefined && updatedName !== null) {
        updatedFields.push('Name');
        const { data, error } = await supabase
          .from('user-profiles')
          .update({name: updatedName})
          .eq('user_id', userProfile?.user_id);
        console.log(data);
        if (error) {
          setErrorMessage("Error updating name; Please try again later");
          return;
        }
      }
      if (updatedEmail !== originalEmail && updatedEmail !== "" && updatedEmail !== undefined && updatedEmail !== null) {
        console.log(validateEmail(updatedEmail));
        if (!(await validateEmail(updatedEmail))) {
          setErrorMessage("Please enter a valid email");
          return; // Exit function early
        }  
        updatedFields.push('Email');

        const { data, error } = await supabase
          .from('user-profiles')
          .update({ email: updatedEmail })
          .eq('user_id', userProfile?.user_id);

        const { data: serviceData, error: serviceError } = await supabase
          .from('service-accounts')
          .update({ email: updatedEmail })
          .eq('user_id', userProfile?.user_id);

        const { data: updateUserData, error: updateUserError } = await supabase.auth.updateUser({
          email: updatedEmail, 
        });

        alert('Please check your inbox to confirm your new email');
        console.log(data, updateUserData);
        console.log(serviceData, updateUserData);
        if (error || updateUserError || serviceError) {
          setErrorMessage("Error updating email; Please try again later");
          return;
        }
      }
      if (updatedPassword !== '' && updatedPassword !== null && updatedPassword !== undefined) {
        updatedFields.push('Password');
        // Check if password and confirm password match
        if (updatedPassword !== confirmPassword) {
          setErrorMessage("Passwords do not match. Please try again.");
          return;
        }
        // Validate the password
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
        if (!passwordRegex.test(updatedPassword)) {
          setErrorMessage("Password must be at least 8 characters long and contain at least one uppercase letter, one digit, and one symbol.");
          return;
        }
        const { data: updatePasswordData, error: updatePasswordError } = await supabase.auth.updateUser({
          password: updatedPassword
        });
        console.log(updatePasswordData);
        if (updatePasswordError) {
          setErrorMessage("Error updating password; Please try again later");
          return;
        }
      }

      // Display message about updated fields
      if (updatedFields.length > 0) {
        alert(`Updated the following account information: ${updatedFields.join(', ')}`);
      } else {
        alert('No changes detected.');
      }

    } catch (error) {
      console.error('Error saving profile:', error);
      setErrorMessage("Error saving profile");
    }
  };

  // Function to handle button clicks that may trigger a popup or external link
  const handleButtonClick = (url: string) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null; // Avoid setting opener to prevent security risks
      newWindow.focus(); // Bring the new window to the foreground
    } else {
      // Handle case where the new window couldn't be opened (probably due to popup blocker)
      alert('Failed to open pop up. Please check your browser settings.');
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <GlobalStyles /> {/* Include global styles */}
      <Helmet>
        <title>EcoTrove | Manage Your Account</title>
        <meta name="description" content="Manage your EcoTrove fixed-price clean energy subscription up to 10% cheaper. See how much you are saving in money and carbon emissions." />
        <meta name="keywords" content="manage account, login, fixed-price energy, clean energy subscription, lower energy bills, EcoTrove, green power" />
      </Helmet>
      <Navbar />
      <PageContainer>
        <ContentContainer>
          <Heading>Manage Your Account</Heading>
          <RoundedRectangle>
            <DetailsTitle>Change Personal Details</DetailsTitle>
            <InputContainer>
              <BoldParagraph>Name</BoldParagraph>
              <InputBox 
                placeholder={originalName}
                value={updatedName}
                onChange={handleNameChange}
              />
            </InputContainer>
            <InputContainer>
              <BoldParagraph>Email Address</BoldParagraph>
              <InputBox 
                placeholder={originalEmail}
                value={updatedEmail}
                onChange={handleEmailChange}
              />
            </InputContainer>
            <InputContainer>
              <BoldParagraph>Password</BoldParagraph>
              <InputBox 
                type="password"
                placeholder="Click to change Password"
                value={updatedPassword}
                onChange={handlePasswordChange}
              />
            </InputContainer>
            <InputContainer>
              <BoldParagraph>Confirm Password</BoldParagraph>
              <InputBox 
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              {errorMessage && <div className="error-message">{errorMessage}</div>}
            </InputContainer>
            <Button onClick={handleSaveProfile}>Save Profile</Button>
          </RoundedRectangle>
          <RoundedRectangle>
            <DetailsTitle>Support</DetailsTitle>
            <BoldParagraph>Contact EcoTrove Support with any questions or anticipated changes.</BoldParagraph>
            <p>Notify EcoTrove of any upcoming changes to your energy demand, such as purchasing an electric vehicle, adding a new person to your household, or buying new energy-intensive appliances.</p>
            <p>Contact EcoTrove Support with any questions about your subscription.</p>
            <Button onClick={() => handleButtonClick("mailto:info@ecotrove.com")}>Contact Support</Button>
          </RoundedRectangle>
          <RoundedRectangle>
            <DetailsTitle>Change Subscription</DetailsTitle>
            <BoldParagraph>Subscription Price: ${userProfile?.monthly_quote}/month </BoldParagraph>
            <BoldParagraph>Subscription Start Date: {formatDate(userProfile?.start_date)} </BoldParagraph>
            <Button onClick={handleCancelSubscription}>Cancel Subscription</Button>
          </RoundedRectangle>
          <Button onClick={handleLogout}>Logout</Button>
        </ContentContainer>
        <BackgroundImage>
          <DesktopIframeContainer>
            <iframe
              src={iframeSrc}
              frameBorder="0"
              width="100%"
              height="100%"
              allowTransparency
              title="Dashboard"
            ></iframe>
          </DesktopIframeContainer>
        </BackgroundImage>
        <MobileContainer>
          <MobileIframeContainer>
            <iframe
              src={iframeSrc}
              frameBorder="0"
              width="90%"
              height="100%"
              allowTransparency
              title="Dashboard"
            ></iframe>
          </MobileIframeContainer>
        </MobileContainer>
      </PageContainer>
      <FooterAccount />
    </>
  );
};

export default ManageAccount;
