import { useState } from "react";
import { supabase } from "../supabaseClient";
import styled from "styled-components";
import { UTILITIES_SERVED } from "../constants";
import EcoTroveBlueGraph from "../images/ecotrove_blue_graph.jpeg";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  background-color: #e6f7ff;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ZipCodeSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start; // Ensure left alignment
  justify-content: center;
  background-color: #e6f7ff;
  width: 50%;
  padding: 50px 25px 50px 80px;
  text-align: left;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    text-align: center;
    margin-left:0px;
    padding: 30px 0px;
  }
`;

const Container = styled.section`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const ImageContainer = styled.section`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 50px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: "Source Sans Pro";
  font-weight: bold;
  color: #111d57;
  margin: 20px 0 20px 0;
  line-height: 1.5;
  text-align: left;
  margin-left: 0; // Removed left padding
  @media (max-width: 768px) {
    font-size: 26px;
    padding: 20px 20px 0;
    text-align: center;
  }
`;

const Subtitle = styled.h2`
  color: #333333;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: normal;
  font-family: 'Source Sans Pro';
  line-height: 1.5;
  text-align: left;
  margin-left: 0; // Removed left padding
  @media (max-width: 768px) {
    font-size: 20px;
    padding: 20px 20px 0;
    text-align: center;
  }
`;

const ZipCodeForm = styled.form`
  display: flex;
  justify-content: flex-start;
  gap: 0;
  margin-left: 0; // Ensure left alignment
  margin-bottom: 50px;
  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 20px 20px 0;
  }
`;

const ZipCodeInput = styled.input`
  padding: 10px;
  border: 2px solid #111d57;
  border-radius: 5px;
  font-size: 22px;
  background-color: #e6f7ff;
  height: 30px;
  width: 300px;
  margin-right: 0;
  @media (max-width: 768px) {
    height: 30px;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 18px;
  font-family: 'Source Sans Pro';
  margin-left: 0; // Ensure left alignment
`;

const EnterButton = styled.button`
  padding: 10px 20px;
  background-color: #284389;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: bold;
  height: 53px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  @media (max-width: 768px) {
    height: 51px;
  }

  &:hover {
    background-color: #111d57;
  }
`;

interface EnterZipCodeProps {
  advanceStage: (nextStage: string) => void;
}

const EnterZipCode: React.FC<EnterZipCodeProps> = ({ advanceStage }) => {
  const [zipcode, setZipcode] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipcode(event.target.value);
    if (error) setError("");
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const zipCodePattern = /^\d{5}(-\d{4})?$/;
    if (!zipCodePattern.test(zipcode)) {
      setError("Please enter a valid zip code.");
      return;
    }

    setError("");

    const { data: interestUserData } = await supabase
      .from("interest-users")
      .insert([{ zipcode: zipcode }])
      .select();

    if (interestUserData && interestUserData.length > 0) {
      const recordId = interestUserData[0].id;
      localStorage.setItem("record_id", recordId.toString());
    }

    const { data: zipCodeData, error } = await supabase
      .from("pge_zipcodes_new")
      .select()
      .eq("zipcode", zipcode)
      .in("utility_name", UTILITIES_SERVED);

    if (error) {
      console.log(error);
    } else if (zipCodeData) {
      if (zipCodeData.length > 0) {
        advanceStage("connectPGE");
        window.scrollTo(0, 0);
      } else {
        advanceStage("waitlistEmail");
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <>
      <Wrapper>
      <GlobalStyles /> {/* Include global styles */}
      <PixelTracking />
        <ZipCodeSection>
          <Container>
            <Title>Enter your zipcode to get a free savings estimate</Title>
            <Subtitle>We will locate your local utility accounts and show how you can save up to 10% on your power bill</Subtitle>
            <ZipCodeForm onSubmit={handleSubmit}>
              <ZipCodeInput
                type="text"
                placeholder="Your zipcode..."
                value={zipcode}
                onChange={handleInputChange}
                className={error ? "is-invalid" : ""}
              />
              <EnterButton type="submit">Enter</EnterButton>
            </ZipCodeForm>
            {error && <ErrorText>{error}</ErrorText>}
          </Container>
        </ZipCodeSection>
        <ImageContainer>
          <img src={EcoTroveBlueGraph} alt="EcoTrove Savings Graph" />
        </ImageContainer>
      </Wrapper>
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent component="Hero_Footer" />
      </PlasmicRootProvider>
    </>
  );
};

export default EnterZipCode;
